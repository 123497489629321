// ImageSelector.js

import React, { useState } from 'react';
import { MdAutorenew, MdFileUpload,  MdEdit, MdSend } from "react-icons/md";
import { ChakraProvider, RadioGroup, Radio, Stack } from "@chakra-ui/react";

const AIImageControls = ({ onStyleSelect, onPromptSubmit, isMob }) => {
    const [prompt, setPrompt] = useState('');
    const [isTyping, setIsTyping] = useState(false);

    const styles = [
        { id: 'manga', label: 'Manga', icon: '🎨' },
        { id: 'oil-painting', label: 'Pintura a Óleo', icon: '🖼️' },
        { id: 'iphone-photo', label: 'Foto iPhone', icon: '📱' },
        { id: 'watercolor', label: 'Aquarela', icon: '🎨' },
        { id: 'pencil-sketch', label: 'Desenho', icon: '✏️' },
        { id: 'digital-art', label: 'Arte Digital', icon: '💻' },
        { id: 'landscape', label: 'Paisagem', icon: '🌄' },
        { id: 'pixel-art', label: 'Pixel Art', icon: '👾' },
        { id: 'anime', label: 'Anime', icon: '🎭' },
        { id: 'cartoon', label: 'Cartoon', icon: '📺' },
        { id: '3d-render', label: '3D', icon: '🎮' },
        { id: 'pop-art', label: 'Pop Art', icon: '🎪' },
        { id: 'minimalist', label: 'Minimalista', icon: '⚪' },
        { id: 'realistic', label: 'Realista', icon: '📸' }, 
    ];

    const handleSubmit = () => {
        if (prompt.trim()) {
            onPromptSubmit(prompt);
            setIsTyping(false);
        }
    };

    return (
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: isMob ? '0.5rem' : '1rem',
            width: isMob ? '100%' : '50%' 
        }}>
            {/* Carrossel de estilos */}
            <div style={{ position: 'relative', width: '100%'}}>
                <div style={{ 
                    overflowX: 'auto',
                    scrollbarWidth: 'thin',
                    maxWidth: '800px',
                    borderRadius: '5px',
                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)'
                }}>
                    <div style={{ 
                        display: 'flex',
                        gap: '0.5rem',
                        paddingBottom: isMob ? '0.5rem' : '0.8rem',
                        paddingTop: isMob ? '0.5rem' : '0.8rem'
                        
                    }}>
                        {styles.map((style) => (
                            <button
                                key={style.id}
                                onClick={() => onStyleSelect(style.id)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#757575',
                                    gap: '0.5rem',
                                    padding: '0.1rem 0.6rem',
                                    fontSize: '0.875rem',
                                    whiteSpace: 'nowrap',
                                    borderRadius: '9999px',
                                    backgroundColor: 'white',
                                    border: '1px solid #e5e7eb',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.2s'
                                }}
                            >
                                <span style={{ fontSize: '1.25rem' }}>{style.icon}</span>
                                <span>{style.label}</span>
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            {/* Área do prompt */}
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <textarea
                    value={prompt}
                    onChange={(e) => {
                        setPrompt(e.target.value);
                        setIsTyping(true);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSubmit();
                        }
                    }}
                    placeholder="Descreva a imagem que você quer criar..."
                    style={{
                        width: '100%',
                        height: '100%',
                        padding: '1rem',
                        paddingRight: '3rem',
                        borderRadius: '5px',
                        resize: 'none',
                        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)'
                    }}
                />
                <button
                    onClick={handleSubmit}
                    style={{
                        position: 'absolute',
                        right: '0.75rem',
                        bottom: '0.75rem',
                        padding: '0.5rem',
                        borderRadius: '9999px',
                        backgroundColor: '#9333ea',
                        color: 'white',
                        cursor: 'pointer',
                        transition: 'background-color 0.2s'
                    }}
                >
                    {isTyping ? <MdEdit size={20} /> : <MdSend size={20} />}
                </button>
            </div>
        </div>
    );
};

function ImageSelector({ onSearchTermChange, onHandlePointerMove, onNewImagesClick, thumbnailImages, onImageSelected, searchTerm, text, isLoading, selectedImage, onSourceTypeChange, imageSourceType, onFileUpload, isMob, selectedOwnImage, slideId, t }) {
    return (
        <div className="divAreaImage">
            <div className="buttonGroupImg">
                <textarea
                    value={text}
                    className={`inputAreaTxt ${(imageSourceType === 'own' || imageSourceType === 'ai') ? 'inputAreaTxtFullWidth' : ''}`}
                />
                <div className="btnNew" hidden={imageSourceType === 'own' || imageSourceType === 'ai'}>
                    <input
                        type="text"
                        onChange={onSearchTermChange}
                        value={searchTerm}
                        className='inputFocusTxt'
                        placeholder={t('main', 'searchPlaceholder')}
                        onPointerMove={onHandlePointerMove}
                        onFocus={(e) => e.target.value === e.target.defaultValue && (e.target.value = '')}
                    />
                    <button
                        className="googleButton buttonStyle"
                        style={{ width: '160px', marginTop: '0px' }}
                        onClick={onNewImagesClick}
                    >
                        <span className="buttonContent"> <MdAutorenew className="buttonIcon" /> {t('main', 'newImages')}</span>
                    </button>
                </div>
            </div>
            {/*  <div className={`imagesContainer ${imageSourceType === 'own' ? 'imagesOwn' : 'imagesBank'}`}> */}
            <div className={`imagesContainer ${imageSourceType === 'bank' ? 'imagesBank' : 'imagesOwn'}`}>
                {isLoading ? (
                    <div className="spinner_4"></div> // Mostra o spinner se estiver carregando
                ) : imageSourceType === "bank" ? (
                    thumbnailImages.map((thumbnailUrl, index) => (
                        <div
                            key={index}
                            className={`divImagesAjs ${thumbnailUrl.full === selectedImage ? "selected" : ""}`}
                            onMouseOver={(e) => e.currentTarget.querySelector('.overlay').style.opacity = 0.5}
                            onMouseOut={(e) => e.currentTarget.querySelector('.overlay').style.opacity = 0}
                            onClick={() => onImageSelected(thumbnailUrl)}
                        >
                            <img
                                src={thumbnailUrl.thumbnail}
                                alt={`Thumbnail ${index + 1}`}
                                className="thumbnailImage"
                            />

                            <div className="overlay" style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'black',
                                opacity: 0,
                                transition: 'opacity 0.3s'
                            }}></div>
                            <div style={{
                                position: 'absolute',
                                bottom: '10px',
                                left: '30%',
                                transform: 'translateX(-20%)',
                                color: 'white',
                                fontSize: '10px',
                                textAlign: 'center'
                            }}

                                hidden={thumbnailUrl.thumbnail && !thumbnailUrl.photographer}

                            >
                                <span> This </span>
                                <a
                                    href={thumbnailUrl.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: 'white' }}
                                    onClick={e => e.stopPropagation()} // Adicione esta linha
                                >
                                    Photo
                                </a>
                                <span> was taken by </span>
                                <a
                                    href={thumbnailUrl.photographer_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: 'white' }}
                                    onClick={e => e.stopPropagation()} // Adicione esta linha
                                >
                                    {thumbnailUrl.photographer}
                                </a>
                                <span> on Pexels.</span>
                            </div>
                        </div>
                    ))) : imageSourceType === "ai" ? (
                        <div className="IAContainer">
                            <div className="imagePreviewArea">
                                {isLoading ? (
                                    <div className="spinner_4"></div> // Substitua pelo spinner de sua preferência
                                ) : (
                                    <>
                                        <img className='imagePreview' alt="Preview" src={selectedOwnImage} />
                                        <div className="fileSizeNote">{t('main', 'maxFileSize')}</div>
                                    </>
                                )}
                            </div>
                            {/* Pode ser similar ao 'own' ou personalizado conforme necessidade */}
                            <AIImageControls
                                onStyleSelect={(styleId) => {
                                    // Lógica para quando um estilo é selecionado
                                    console.log('Estilo selecionado:', styleId);
                                }}
                                onPromptSubmit={(promptText) => {
                                    // Lógica para quando o prompt é enviado
                                    console.log('Prompt enviado:', promptText);
                                }}

                                isMob = {(isMob)}
                            />
                        </div>
                    ) : (
                    <div className="uploadContainer">
                        <div className="imagePreviewArea">
                            {isLoading ? (
                                <div className="spinner_4"></div> // Substitua pelo spinner de sua preferência
                            ) : (
                                <>
                                    <img className='imagePreview' alt="Preview" src={selectedOwnImage} />
                                    <div className="fileSizeNote">{t('main', 'maxFileSize')}</div>
                                </>
                            )}
                        </div>
                        <div className="fileInputContainer">
                            <input type="file" id={`customFileInput-${slideId}`} onChange={onFileUpload} className="fileInput" hidden />
                            <label htmlFor={`customFileInput-${slideId}`} className="customFileButton">
                                <span> <MdFileUpload className="buttonIcon" /></span>{t('main', 'chooseFile')}
                            </label>
                        </div>
                    </div>
                )}
            </div>
            <div className="radio-group-footer">
                {/* Aqui vai o componente com os RadioGroups */}
                <ChakraProvider>
                    <RadioGroup onChange={onSourceTypeChange} value={imageSourceType} >
                        <Stack direction='row'>
                            <Radio value="ai" colorScheme="purple">{isMob ? t('main', 'aiImagesShort') : t('main', 'aiImages')}</Radio>
                            <Radio value="bank" colorScheme="purple">{isMob ? t('main', 'imageBankShort') : t('main', 'imageBank')}</Radio>
                            <Radio value="own" colorScheme="purple">{isMob ? t('main', 'ownImagesShort') : t('main', 'ownImages')}</Radio>
                        </Stack>
                    </RadioGroup>
                </ChakraProvider>
            </div>
        </div>
    );
}

export default ImageSelector;


